import Vue from "vue"
import VueRouter from "vue-router"
import Scans from "../views/Scans.vue"
import User from "../views/User.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/scans"
  },
  {
    path: "/login",
    beforeEnter() {
      window.location.href = process.env.VUE_APP_API_URI + "/.auth/login/google?post_login_redirect_url=" + location.origin + "/saveuser"
    },
    meta: {
      guest: true
    }
  },
  {
    path: "/saveuser",
    name: "saveuser",
    component: User,
    meta: {
      guest: true
    },
  },
  {
    path: "/scans",
    name: "scans",
    component: Scans,
    props: {
      search: {
        type: String,
        required: true
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/help",
    name: "help",
    meta: {
      requiresAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Help.vue")
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !sessionStorage.getItem(process.env.VUE_APP_LOCAL_SESSION_NAME)) {
    next({
      path: '/login'
    })
  } else {
    next()
  }
})

export default router
