//import SignalR from './signalr';
import Vue from "vue";
import App from "./App.vue";
//import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from 'axios';
import 'ag-grid-enterprise';
import { apolloProvider } from './graphql/apollo';

Vue.config.productionTip = false;
axios.defaults.baseURL = process.env.VUE_APP_API_URI
Vue.prototype.$http = axios

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App),
}).$mount("#app");
