import gql from 'graphql-tag'
export const scansByStage = gql`
    query scansByStage($stageId: String!) {
        scansByStage(stageId: $stageId) {
            id
            name
            ipAddress
            locRoom
            locRack
            category
            deviceTypeId
            error
            lastScan
            data {
                endpoint
                valueNum
                valueString
                error
            }
            credential {
                type
                port
                snmpVersion
                community
            }
            trackers {
                id
                type
                pollingInterval
                error
            }
        }
    }
`
export const deviceTypes = gql`
    query deviceTypes {
        deviceTypes {
            id
            manufacturer
            model
            parameters {
                endpoint
                name
                description
                access
                status
                structure
                dataType
                options {
                    name
                    value
                    description
                    warning
                }
            }
        }
    }
`
export const parameterDescriptions = gql`
    query parameterDescriptions {
        parameterDescriptions {
            endpoint
            name
            description
        }
    }
`
export const me = gql`
    query me {
        me {
            id
            name
            email
            roles
            stages {
               id
               role
            }
        }
    }
`
