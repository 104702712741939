<template>
  <div></div>
</template>

<script>
import { me } from "../graphql/queries"
export default {
  name: "User",
  components: {

  },
  methods: {
    async getMe() {
      let meObj = await this.$apollo.query({
        query: me
      })
      if (meObj?.data?.me) {
        sessionStorage.setItem(process.env.VUE_APP_LOCAL_SESSION_NAME, JSON.stringify(meObj?.data?.me))
        this.$router.push({name:'scans'})
      }
    }
  },
  mounted() {
    this.getMe()
  },
};
</script>

<style scoped lang="scss">

</style>
