<template>
  <div class="wrapper">
    <div style="display: flex; margin: 5px 0px 5px 0px; width: 100%; ">
        <div style="text-align: left; width: 80%; ">
          <input type="text" id="filter-text-box" v-model="search" v-on:input="onFilterTextBoxChanged()" placeholder="Filter..."/>&nbsp;
          <span style="margin-left: 10px;">Last Scan:&nbsp;</span><span style="color: #FFCA00"><b>{{ maxDate }}</b></span>&nbsp;
          <span style="margin-left: 10px; ">Errors:&nbsp;</span><span style="color: red;"><b>{{ errorCount }}</b></span>&nbsp;
          <span style="margin: 0px 5px; color: red; min-width: 300px;" id="error">{{ error }}</span>&nbsp;
          <span style="margin: 0px 5px; color: red; min-width: 300px;" id="error">{{ sync }}</span>&nbsp;
        </div>
        <div style="text-align: right; width: 20%; ">
          <button v-on:click="toggleAll()" title="Toggle All Groups">Toggle</button>&nbsp;
          <button v-on:click="saveState(true)" title="Save Grid Config">Save</button>&nbsp;
          <button v-on:click="restoreState(true)" title="Restore Grid Config">Restore</button>&nbsp;
          <button v-on:click="clearLocalCache()" title="Clear Local Cache">Clear</button>
        </div>
    </div>
    <div style="height: 100%;">
      <ag-grid-vue
        id="myGrid"
        style="width: 100%; height: 100%; padding: 0px; margin: 0px; margin-bottom: 0px; "
        class="ag-theme-balham-dark"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :debug="false"
        :rowData="rowData"
        :masterDetail="true"
        :isRowMaster="isRowMaster"
        :defaultColGroupDef="defaultColGroupDef"
        :detailCellRendererParams="detailCellRendererParams"
        :sideBar="sideBar"
        :defaultColDef="defaultColDef"
        :statusBar="statusBar"
        :animateRows="true"
        :enableRangeSelection="true"
        :enableBrowserTooltips="true"
        :multiSortKey="multiSortKey"
        :keepDetailRows="true"
        :getRowNodeId="getRowNodeId"
        @grid-ready="gridReady"
        :cellFlashDelay="cellFlashDelay"
        :cellFadeDelay="cellFadeDelay"
      ></ag-grid-vue>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue"
import { scansByStage, parameterDescriptions } from "../graphql/queries"
window.oidNames = {}
window.oidDescriptions = {}
export default {
  name: "Scans",
  props: {

  },
  data() {
    return {
      gridOptions: null,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      isRowMaster: null,
      sideBar: null,
      statusBar: null,
      animateRows: null,
      keepDetailsRows: null,
      detailCellRendererParams: null,
      defaultColDef: null,
      getRowNodeId: null,
      multiSortKey: null,
      defaultColGroupDef: null,
      autoGroupColumnDef: null,
      search: '',
      error: '',
      cellFlashDelay: null,
      cellFadeDelay: null,
      nameObj: null,
      descriptionObj: null,
      maxDate: 'Loading...',
      errorCount: 0,
      myInterval: null,
      syncTime: 10000,
      currentEvent: '',
      dataLoaded: null,
      sync: null
    }
  },
  components: {
    AgGridVue,
  },
  created() {
    
  },
  methods: {
    gridReady(params) {
      params.api.setDomLayout('autoHeight')
    },
    onFilterTextBoxChanged() {
      this.gridOptions.api.setQuickFilter(this.search)
      this.gridOptions.api.forEachDetailGridInfo(detailGridInfo => {
        detailGridInfo.api.setQuickFilter(this.search);
      });
    },
    toggleAll() {
      this.gridApi.forEachNode(function (node) {
        node.expanded = !window.collapsed
      })
      window.collapsed = !window.collapsed
      this.gridApi.onGroupExpandedOrCollapsed()
    },
    saveState(override) {
      let gridconfig = localStorage.getItem('end-point-agent-grid-config')
      if (!gridconfig || override) {
        this.setColSort()
        localStorage.setItem('end-point-agent-grid-config', JSON.stringify(this.gridColumnApi.getColumnState()))
        this.error = 'Grid config saved.'
      }
    },
    restoreState(user) {
      let gridconfig = JSON.parse(localStorage.getItem('end-point-agent-grid-config'))
      if (!gridconfig) {
        return
      }
      this.gridColumnApi.applyColumnState({
        state: gridconfig,
        applyOrder: true,
      })
      if (user)
        this.error = 'Grid config restored.'
    },
    clearLocalCache() {
      localStorage.removeItem('end-point-agent-grid-config')
    },
    async getNameDescriptionData() {
      let devicesArr = await this.$apollo.query({
        query: parameterDescriptions,
      })
      devicesArr?.data?.parameterDescriptions.forEach((value) => { 
        window.oidNames[value.endpoint] = value.name
        window.oidDescriptions[value.endpoint] = value.description
      })
    },
    async getGridData() {
      let scansArr = await this.$apollo.query({
        query: scansByStage,
        variables: {
          stageId: JSON.parse(sessionStorage.getItem(process.env.VUE_APP_LOCAL_SESSION_NAME)).stages[0].id,
        },
      })
      if (scansArr?.data?.scansByStage) {
        let scanData = this.getSearchableOrRowData(scansArr?.data?.scansByStage)
        if (this.dataLoaded != null) {
          let tran = { update: scanData }
          this.gridApi?.applyTransaction(tran)
        } else {
          this.rowData = scanData
          this.dataLoaded = true
        }
        this.refreshLastScanErrors(scansArr?.data?.scansByStage)
      }
    },
    refreshLastScanErrors(data) {
      let thisDate
      let newDate = new Date('12/1/2020')
      let newCount = 0
      data.forEach(function (row) {
        thisDate = new Date(row.lastScan)
        if (thisDate != null && thisDate > newDate)
          newDate = thisDate
        if (row.error != null)
          newCount++
      })
      this.maxDate = newDate.toLocaleString()
      this.errorCount = newCount
    },
    startTimer() {
      if (this.currentEvent == 'focus') return
      this.currentEvent = 'focus'
      this.sync = ''
      this.myInterval = window.setInterval(this.timerCall, this.syncTime)
    },
    stopTimer() {
      if (this.currentEvent == 'blur') return
      this.currentEvent = 'blur'
      this.sync = 'Sync Paused.'
      window.clearInterval(this.myInterval)
    },
    timerCall() {
      this.getGridData()
    },
    setColSort() {
      this.gridColumnApi.applyColumnState({
          state: [
            { colId: 'error', sort: 'desc', sortIndex: 0 },
            { colId: 'name', sort: 'asc', sortIndex: 1 }
          ]
      })
    },
    getSearchableOrRowData(rowData) {
      let searchableOrRowData = rowData.map(masterRow => {
        return {
            ...masterRow,
            searchableOrDetailRows: masterRow.data.map(oid => Object.values(oid).join(' ')).join(' ')
        }
      });
      return searchableOrRowData;
    }
  },
  beforeMount() {
    this.gridOptions = {}
    this.columnDefs = [
      {headerName: 'Name', field: 'name', width: 250, cellRenderer: 'agGroupCellRenderer' },
      {headerName: 'Scan Date', field: 'lastScan', width: 200, valueFormatter: function(params) { return (params.value != null) ? new Date(params.value).toLocaleString() : null } },
      {headerName: 'Error', field: 'error', minWidth: 300, flex: 1, cellStyle: function(params) { return (params.value != null) ? {color: 'red', textAlign: 'left'} : null }, }, //cellRenderer: 'agAnimateShowChangeCellRenderer' },
      {headerName: 'Category', field: 'category', width: 150 },
      {headerName: 'Room', field: 'locRoom', width: 150 },
      {headerName: 'Rack', field: 'locRack', width: 150 },
      {headerName: 'IP Address', field: 'ipAddress', width: 150 },
      {headerName: 'Type', field: 'credential.type', width: 150, hide: true },
      {headerName: 'Port', field: 'credential.port', width: 150, hide: true },
      {headerName: 'Version', field: 'credential.snmpVersion', width: 150, hide: true },
      {headerName: 'Community', field: 'credential.community', width: 150, hide: true },
      {headerName: 'ID', field: 'id', minWidth: 275, hide: true },
      {field: 'searchableOrDetailRows', hide: true}
    ]
    this.defaultColDef = {
      filter: true,
      sortable: true,
      resizable: true,
      enableValue: true,
      enableRowGroup: true,
      enableCellChangeFlash: true,
      enablePivot: false,
      cellStyle: {textAlign: 'left'},
      cellRenderer: 'agAnimateShowChangeCellRenderer',
    }
    this.cellFlashDelay = 1000
    this.cellFadeDelay = 500
    this.defaultColGroupDef = {}
    this.detailCellRendererParams = {
      refreshStrategy: 'rows',
      detailGridOptions: {
        rowSelection: 'multiple',
        enableCellChangeFlash: true,
        cellFlashDelay: 1000,
        cellFadeDelay: 500,
        immutableData: true,
        getRowNodeId: (data) => { return data.endpoint },
        columnDefs: [
          {headerName: 'OID', field: 'endpoint', width: 150 },
          { headerName: 'Name', field: 'endpoint', width: 150, getQuickFilterText: function(params) { return params.value.name },
            valueGetter: function(params) { return window.oidNames[params.data.endpoint] }
          },
          {headerName: 'Value', width: 350, type: 'rightAligned', cellStyle: function() { return {align: 'right'} },
            valueGetter: function(params) { return (params.data.valueString !== null) ? params.data.valueString : new Intl.NumberFormat(navigator.language).format(params.data.valueNum) } 
          },
          {headerName: 'Error', field: 'error', width: 300, cellStyle: function(params) { return (params.value != null) ? {color: 'red'} : null } },
          { headerName: 'Description', field: 'endpoint', minWidth: 300, flex: 1, getQuickFilterText: function(params) { return params.value.name },
            valueGetter: function(params) { return window.oidDescriptions[params.data.endpoint] }
          },
        ],
        defaultColDef: this.defaultColDef
      },
      getDetailRowData: function(params) {
        params.successCallback(params.data.data)
      }
    }
    this.sideBar = ['columns', 'filters']
    this.isRowMaster = (dataItem) => {
      return dataItem ? dataItem.data.length > 0 : false
    }
    this.statusBar = {
      statusPanels: [
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agFilteredRowCountComponent', align: 'right' },
        // { statusPanel: 'agSelectedRowCountComponent', align: 'right' },
        { statusPanel: 'agAggregationComponent', align: 'right' },
      ],
    }
    this.multiSortKey = 'ctrl'
    this.getRowNodeId = (data) => { return data.id }
    this.autoGroupColumnDef = { width: 250 }
    this.asyncTransactionWaitMillis = 5000
  },
  async mounted() {
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
    this.getNameDescriptionData()
    this.getGridData()
    this.startTimer()
    window.addEventListener('focus', this.startTimer)
    window.addEventListener('mousemove', this.startTimer)
    window.addEventListener('blur', this.stopTimer)
    this.saveState(false)
    this.restoreState()
  },
}

</script>

<style scoped lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
#myGrid {
  flex: 1 1 0px;
  width: 100%;
}
.button-group {
  padding-bottom: 4px;
  display: inline-block;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 13px;
}
.number { 
  text-align: right;
}
.ag-row-level-0 { 
  font-weight: bold; 
}
.ag-row-level-1 { 
  color: lightblue; 
}
.ag-row-level-2 { 
  color: lightyellow; 
}
button {
  background-color: black;
  color: white;
}
input {
  background-color: black;
  color: white;
}
input::placeholder {
  background-color: black;
  color: white;
}
.ag-theme-balham-dark .ag-row, .ag-cell-data-changed, .ag-value-change-value-highlight, .ag-row {
  background-color: #FFCA00 !important;
}
</style>
